import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProfileImportancePage = ({ title, buttons }) => {
    const navigate = useNavigate();

    const onClick = (path) => {
        console.log(path);
        navigate(path);
    }

  return (
    <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
        <Grid container mb={4}>
            <Grid item md={9}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src="Spark-Logo-sm.png" alt="Spark Interventions LLC" style={{ width: '25%', height: 'auto' }} />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={8} padding={4}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item style={{ padding: '2px' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" sx={{textDecoration: 'underline'}} display="inline" align="center">Why Are Profiles Useful?</Typography>
                            </Grid>
                            <Grid item>
                                    <Typography component="div" variant="body1">
                                        <ul style={{ listStyle: 'inside' }}>
                                            <li style={{ textAlign: 'center' }}>In the demo, we only test for dyslexia</li>
                                            <li style={{ textAlign: 'center' }}>Most individuals present multiple neurodiversity behaviors</li>
                                            <li style={{ textAlign: 'center' }}>These co-morbid learning differences are represented in an individual's profile</li>
                                            <li style={{ textAlign: 'center' }}>This assists the therapist, researcher, and caregiver to tailor the intervention to the individual's profile</li>
                                        </ul>
                                    </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center" sx={{ mt: 4 }}>
                        {buttons.map((button, index) => (
                            <Button
                            key={index}
                            variant="contained"
                            color="primary"
                            onClick={() => onClick(button.path)}
                            style={{ margin: '0 8px' }}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
            <img src="interventions.png" alt="Interventions" style={{ width: '100%', height: 'auto' }} />
            </Grid>
        </Grid>
    </Box>
  );
};

export default ProfileImportancePage;
