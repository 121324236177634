import React, { useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CategoriesIndividualPage = ({ title, buttons, imagesCtopp, imagesTowre, imagesSummary, individuals, y_pred, y_true }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentSelection, setCurrentSelection] = useState('CTOPP');

    const navigate = useNavigate();

    const onClick = (path) => {
        console.log(path);
        navigate(path);
    }

    const goToPrevious = () => {
        const isFirstImage = (currentIndex === 0);
        if (!isFirstImage) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
        }
    };
    
    const goToNext = () => {
        const isLastImage = (currentIndex === currentImages.length - 1);
        if (!isLastImage) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
        }
    };

    const handleSelectionChange = (event) => {
        setCurrentSelection(event.target.value);
    };

    const currentImages = currentSelection === 'CTOPP' ? imagesCtopp : currentSelection === 'TOWRE' ? imagesTowre : imagesSummary;

  return (
    <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
        <Grid container mb={4}>
            <Grid item md={9}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src="Spark-Logo-sm.png" alt="Spark Interventions LLC" style={{ width: '25%', height: 'auto' }} />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={8} padding={4}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item style={{ padding: '2px' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" sx={{textDecoration: 'underline'}} align="center">Which Test Categories or Profiles Matter Most for the Individual?</Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="div" variant="body1">
                                    <ul style={{ listStyle: 'inside' }}>
                                        <li style={{ textAlign: 'center' }}>An individual's profiles or clusters are ordered with the most important (longest bars) at the top</li>
                                        <li style={{ textAlign: 'center' }}>The top profiles or clusters are used by the virtual assistant to help ChatGPT retrieve the best responses to the user's queries</li>
                                    </ul>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center" sx={{ mt: 4 }}>
                        {buttons.map((button, index) => (
                            <Button
                            key={index}
                            variant="contained"
                            color="primary"
                            onClick={() => onClick(button.path)}
                            style={{ margin: '0 8px' }}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                {currentImages.length > 0 && (
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <img key={currentIndex} src={`data:image/jpeg;base64,${currentImages[currentIndex]}`} alt={`Slide ${currentIndex}`} style={{ maxWidth: '100%'}} />
                        </Grid>
                        <Grid item mb={2}>
                            <Typography key={individuals[currentIndex]} variant="body1" style={{ color: y_true[currentIndex] === y_pred[currentIndex] ? '#51AF53' : '#951F14' }} align="center">{currentIndex+1}. {individuals[currentIndex]} is {y_true[currentIndex] === 0 ? 'not' : null} dyslexic</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button
                                        key="Previous"
                                        variant="contained"
                                        color="primary"
                                        onClick={goToPrevious}
                                        style={{ margin: '0 8px' }}
                                        disabled={currentIndex === 0}
                                    >
                                        Previous
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        key="Next"
                                        variant="contained"
                                        color="primary"
                                        onClick={goToNext}
                                        style={{ margin: '0 8px' }}
                                        disabled={currentIndex === currentImages.length-1}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                                <Grid item xs={12} alignContent="center" mt={2}>
                                    <RadioGroup aria-label="position" name="position" defaultValue="top" value={currentSelection} onChange={handleSelectionChange} style={{ alignContent: "center" }}>
                                        <FormControlLabel value="CTOPP" control={<Radio />} label="CTOPP" />
                                        <FormControlLabel value="TOWRE" control={<Radio />} label="TOWRE" />
                                        <FormControlLabel value="Summary" control={<Radio />} label="Summary" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    </Box>
  );
};

export default CategoriesIndividualPage;
