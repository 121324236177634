import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import DemoExplainedPage from './pages/DemoExplainedPage';
import SetupPage from './pages/SetupPage';
import EvaluatedIndividualsPage from './pages/EvaluatedIndividualsPage';
import EvaluationResultsPage from './pages/EvaluationResultsPage';
import PredictionQualityPage from './pages/PredictionQualityPage';
import CategoriesPage from './pages/CategoriesPage';
import CategoriesSeparationPage from './pages/CategoriesSeparationPage';
import CategoriesIndividualPage from './pages/CategoriesIndividualPage';
import ProfileProbabilitiesPage from './pages/ProfileProbabilitiesPage';
import ProfileImportancePage from './pages/ProfileImportancePage';

function App() {
  const [results, setResults] = useState({});
  const [tests, setTests] = useState([]);
  const [ctopp, setCtopp] = useState({});
  const [towre, setTowre] = useState({});
  const [summary, setSummary] = useState({});

  const page1Buttons = [
    { label: 'Start Demo', path: '/2' },
  ];
  
  const page2Buttons = [
    { label: 'Back', path: '/' },
    { label: 'Generate Model', path: '/2' },
    { label: 'Continue', path: '/3' },
  ];
  
  const page3Buttons = [
    { label: 'Back', path: '/2' },
    { label: 'Continue', path: '/4' },
  ];
  
  const page4Buttons = [
    { label: 'Back', path: '/3' },
    { label: 'Continue', path: '/5' },
  ];

  const page5Buttons = [
    { label: 'Back', path: '/4' },
    { label: 'Continue', path: '/6' },
  ];
  
  const page6Buttons = [
    { label: 'Back', path: '/5' },
    { label: 'Continue', path: '/7' },
  ];

  const page7Buttons = [
    { label: 'Back', path: '/6' },
    { label: 'Continue', path: '/8' },
  ];
  
  const page8Buttons = [
    { label: 'Back', path: '/7' },
    { label: 'Continue', path: '/9' },
  ];

  const page9Buttons = [
    { label: 'Back', path: '/8' },
    { label: 'Continue', path: '/10' },
  ];
  
  const page10Buttons = [
    { label: 'Back', path: '/9' },
    { label: 'Restart Demo', path: '/' },
  ];

  const modelCallback = (data, tests) => {
    setResults(data);
    setCtopp(data['tests']['CTOPP']);
    setTowre(data['tests']['TOWRE']);
    setSummary(data['tests']['Summary']);
    setTests(tests);
  };
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DemoExplainedPage title="Demo Explained" buttons={page1Buttons}/>} />
        <Route path="/2" element={<SetupPage title="Set-up" buttons={page2Buttons} callback={modelCallback}/>} />
        <Route path="/3" element={<EvaluatedIndividualsPage title="Evaluated Individuals" buttons={page3Buttons} individuals={results['individuals']} tests={tests} />} />
        <Route path="/4" element={<EvaluationResultsPage title="Evaluation Results" buttons={page4Buttons} individuals={results['individuals']} y_pred={results['y_pred']} y_true={results['y_true']}/>} />
        <Route path="/5" element={<PredictionQualityPage title="Prediction Quality" buttons={page5Buttons} report={results['report']}/>} />
        <Route path="/6" element={<CategoriesPage title="Test Categories" buttons={page6Buttons} featuresCtopp={ctopp['top_features']} featuresTowre={towre['top_features']} featuresSummary={summary['top_features']} />} />
        <Route path="/7" element={<CategoriesSeparationPage title="How Do the Test Categories Separate the Individuals into Profiles?" buttons={page7Buttons} imagesCtopp={ctopp['feature_plots']} imagesTowre={towre['feature_plots']} imagesSummary={summary['feature_plots']}/>} />
        <Route path="/8" element={<CategoriesIndividualPage title="Test Categories and Profiles" buttons={page8Buttons} imagesCtopp={ctopp['individual_plots']} imagesTowre={towre['individual_plots']} imagesSummary={summary['individual_plots']} individuals={results['individuals']} y_pred={results['y_pred']} y_true={results['y_true']} />} />
        <Route path="/9" element={<ProfileProbabilitiesPage title="Profile Probabilities" buttons={page9Buttons} plot={results['cluster_probabilities_plot']}/>} />
        <Route path="/10" element={<ProfileImportancePage title="The Importance of Profiles" buttons={page10Buttons}/>} />
      </Routes>
    </Router>
  );
}

export default App;
