import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EvaluatedIndividualsPage = ({ title, buttons, individuals, tests }) => {
    const navigate = useNavigate();

    const onClick = (path) => {
        console.log(path);
        console.log(individuals);
        navigate(path);
    }

  return (
    <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
        <Grid container mb={4}>
            <Grid item md={9}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src="Spark-Logo-sm.png" alt="Spark Interventions LLC" style={{ width: '25%', height: 'auto' }} />
            </Grid>
        </Grid>
    <Grid container>
        <Grid item md={12} padding={4}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item style={{ padding: '2px' }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h6" sx={{textDecoration: 'underline'}} display="inline" align="center">Meet Your Selected Individuals</Typography>
                        </Grid>
                        {individuals.map((individual, index) => (
                            <Grid item>
                                <Typography key={individual} variant="body1" style={{ color: '#1A3B8D' }} align="center">{index+1}. {individual}</Typography>
                            </Grid>
                        ))}
                        <Grid item>
                            <Typography variant="h6" sx={{textDecoration: 'underline'}} display="inline" align="center">They Will be Screened Using Your Selected Tests:</Typography>
                        </Grid>
                        {tests.map((test, index) => (
                            <Grid item>
                                <Typography key={test} variant="body1" style={{ color: '#1A3B8D' }} align="center">{index+1}. {test}</Typography>
                            </Grid>
                        ))}
                        <Grid item>
                            <Typography variant="h6" style={{ color: '#1A3B8D' }} align="center">The Tool Identifies Who Has Dyslexia</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item alignItems="center" sx={{ mt: 4 }}>
                    {buttons.map((button, index) => (
                        <Button
                        key={index}
                        variant="contained"
                        color="primary"
                        onClick={() => onClick(button.path)}
                        style={{ margin: '0 8px' }}
                        >
                            {button.label}
                        </Button>
                    ))}
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EvaluatedIndividualsPage;
