import React from 'react';
import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PredictionQualityPage = ({ title, buttons, report }) => {
    const navigate = useNavigate();

    const onClick = (path) => {
        console.log(path);
        console.log(report);
        navigate(path);
    }

    return (
        <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
            <Grid container mb={4}>
                <Grid item md={9}>
                    <Typography variant="h5">{title}</Typography>
                </Grid>
                <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src="Spark-Logo-sm.png" alt="Spark Interventions LLC" style={{ width: '25%', height: 'auto' }} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={12} padding={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item style={{ padding: '2px' }}>
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6" sx={{textDecoration: 'underline'}} display="inline" align="center">How Well Does the Tool Predict Dyslexia?</Typography>
                                </Grid>
                                <Grid item>
                                    <Table sx={{ mt: 2 }}>
                                        <TableHead sx={{ backgroundColor: '#5383EB' }}>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Prediction</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Precision</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Recall</TableCell>
                                                <TableCell sx={{ color: 'white' }}>F1</TableCell>
                                                <TableCell sx={{ color: 'white' }}># Examples</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Dyslexic</TableCell>
                                                <TableCell>{report['Dyslexic']['precision'].toFixed(2)}</TableCell>
                                                <TableCell>{report['Dyslexic']['recall'].toFixed(2)}</TableCell>
                                                <TableCell>{report['Dyslexic']['f1-score'].toFixed(2)}</TableCell>
                                                <TableCell>{report['Dyslexic']['support']}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Not Dyslexic</TableCell>
                                                <TableCell>{report['Non-Dyslexic']['precision'].toFixed(2)}</TableCell>
                                                <TableCell>{report['Non-Dyslexic']['recall'].toFixed(2)}</TableCell>
                                                <TableCell>{report['Non-Dyslexic']['f1-score'].toFixed(2)}</TableCell>
                                                <TableCell>{report['Non-Dyslexic']['support']}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Accuracy</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{report['accuracy'].toFixed(2)}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Wtd. Average</TableCell>
                                                <TableCell>{report['weighted avg']['precision'].toFixed(2)}</TableCell>
                                                <TableCell>{report['weighted avg']['recall'].toFixed(2)}</TableCell>
                                                <TableCell>{report['weighted avg']['f1-score'].toFixed(2)}</TableCell>
                                                <TableCell>{report['weighted avg']['support']}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item alignItems="center" sx={{ mt: 4 }}>
                            {buttons.map((button, index) => (
                                <Button
                                key={index}
                                variant="contained"
                                color="primary"
                                onClick={() => onClick(button.path)}
                                style={{ margin: '0 8px' }}
                                >
                                    {button.label}
                                </Button>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
    };

export default PredictionQualityPage;
