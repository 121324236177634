import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DemoExplainedPage = ({ title, buttons }) => {
    const navigate = useNavigate();

    const onClick = (path) => {
        console.log(path);
        navigate(path);
    }

  return (
    <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
        <Grid container mb={4}>
            <Grid item md={9}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src="Spark-Logo-sm.png" alt="Spark Interventions LLC" style={{ width: '25%', height: 'auto' }} />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item md={12} padding={12}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item style={{ padding: '2px' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" sx={{textDecoration: 'underline', fontStyle: 'italic'}} display="inline" align="center">The Demonstration Will:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" align="center">1. Detect whether an individual is or is not dyslexic based on results from the evaluations</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" align="center">2. Evaluate how well the model performs, e.g., accuracy, false positives/negatives</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" align="center">3. Explain how the model generates different profiles of the individuals</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" align="center">4. Show the profiles graphically</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center" sx={{ mt: 4 }}>
                        {buttons.map((button, index) => (
                            <Button
                            key={index}
                            variant="contained"
                            color="primary"
                            onClick={() => onClick(button.path)}
                            style={{ margin: '0 8px' }}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>
  );
};

export default DemoExplainedPage;
