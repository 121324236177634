import React, { useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CategoriesPage = ({ title, buttons, featuresCtopp, featuresTowre, featuresSummary }) => {
    const [currentSelection, setCurrentSelection] = useState('CTOPP');

    const navigate = useNavigate();

    const onClick = (path) => {
        console.log(path);
        navigate(path);
    }

    const handleSelectionChange = (event) => {
        setCurrentSelection(event.target.value);
    };

    const features = currentSelection === 'CTOPP' ? featuresCtopp : currentSelection === 'TOWRE' ? featuresTowre : featuresSummary;

  return (
    <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
        <Grid container mb={4}>
            <Grid item md={9}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src="Spark-Logo-sm.png" alt="Spark Interventions LLC" style={{ width: '25%', height: 'auto' }} />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={9} padding={4}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item style={{ padding: '2px' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" sx={{textDecoration: 'underline'}} display="inline" align="center">Which Test Categories Matter Most?</Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="div" variant="body1">
                                    <ul style={{ listStyle: 'inside' }}>
                                        {
                                            currentSelection === 'CTOPP'
                                            ?
                                                <li style={{ textAlign: 'center' }}>CTOPP uses 5 input test categories and generates 2 profiles</li>
                                            :
                                            currentSelection === 'TOWRE'
                                            ?
                                                <li style={{ textAlign: 'center' }}>TOWRE uses 2 input test categories and generates 2 profiles</li>
                                            :
                                                <li style={{ textAlign: 'center' }}>The summary uses 4 input profiles and generates 2 profiles</li>
                                        }
                                        
                                        <li style={{ textAlign: 'center' }}>Individuals in the same cluster represent how close they are to one another based on the question metrics</li>
                                    </ul>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center" sx={{ mt: 4 }}>
                        {buttons.map((button, index) => (
                            <Button
                            key={index}
                            variant="contained"
                            color="primary"
                            onClick={() => onClick(button.path)}
                            style={{ margin: '0 8px' }}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
                <Grid container direction="column" sx={{ backgroundColor: '#DBE6FA' }} padding={6}>
                    <Grid item>
                        {currentSelection === "Summary" 
                        ? 
                            <Typography variant="body1" align="center">Individuals who scored similarly across tests are grouped into the same profiles.</Typography>
                        :
                            <Typography variant="body1" align="center">These are the question categories that matter the most for all individuals:</Typography>
                        }
                        
                    </Grid>
                    {features.map((feature, index) => (
                        <Grid item>
                            <Typography key={feature} variant="body1" style={{ color: index < 2 ? '#339126' : 'inherit' }} align="center">{index+1}. {feature}</Typography>
                        </Grid>
                    ))}
                </Grid>
                <Grid container direction="column">
                    <Grid item xs={12} alignContent="center" mt={2}>
                        <RadioGroup aria-label="position" name="position" defaultValue="top" value={currentSelection} onChange={handleSelectionChange} style={{ alignContent: "center" }}>
                            <FormControlLabel value="CTOPP" control={<Radio />} label="CTOPP" />
                            <FormControlLabel value="TOWRE" control={<Radio />} label="TOWRE" />
                            <FormControlLabel value="Summary" control={<Radio />} label="Summary" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>
  );
};

export default CategoriesPage;
