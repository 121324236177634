import React, {useState} from 'react';
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const table = [
    { label: 'Enter the number of individuals to be evaluated (1 to 16):', description: 'N individuals'},
    { label: 'How many individuals are dyslexic? (1 to 8):', description: 'N individuals'},
    { label: 'Select the tests results used for the screening (select at least 1):', description: 'CTOPP'},
    { label: '', description: 'TOWRE'},
];

const SetupPage = ({ title, buttons, callback }) => {
    const [filledIndices, setFilledIndices] = useState([2, 3]);
    const [numIndividuals, setNumIndividuals] = useState('');
    const [numDyslexic, setNumDyslexic] = useState('');
    const [hasResults, setHasResults] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const onClick = async (path) => {
        if (path === '/2') {
            const tests = filledIndices.filter(index => index < table.length).map(index => table[index].description);
            const request_data = {
                num_individuals: parseInt(numIndividuals) || 16,
                num_dyslexic: parseInt(numDyslexic) || 4,
                tests: tests,
            };
            setLoading(true);
            try {
                console.log(request_data);
                const url = process.env.REACT_APP_API_URL + "/api/pipeline";
                const response = await axios.post(url, request_data);
                console.log('Response:', response.data);
                callback(response.data, tests);
            } catch (error) {
                console.error('Error making POST request:', error);
            }
            setLoading(false);
            setHasResults(true);
        } else {
            navigate(path);
        }
    }

  return (
    <div className="spinner-overlay">
      {loading 
      && 
        <Paper style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white
            zIndex: 1000 // Ensure it's above other content
        }}>
            <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%'}} />
        </Paper>
      }
        <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
            <Typography variant="h5">{title}</Typography>
        <Grid container>
            <Grid item xs={12} md={9} padding={4}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item style={{ padding: '2px' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" sx={{textDecoration: 'underline'}} display="inline" align="center">Configure the Demo:</Typography>
                            </Grid>
                            <Grid item>
                                <Table>
                                    <TableBody>
                                        {table.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.label}</TableCell>
                                            <TableCell>
                                                {index === 0
                                                ?
                                                    <TextField 
                                                        label={`N Individuals`} 
                                                        variant="outlined" 
                                                        size="small" 
                                                        value={numIndividuals} 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*$/.test(value)) {
                                                                if (value === '' || (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 16)) {
                                                                    setNumIndividuals(value);
                                                                }
                                                            }
                                                        }} 
                                                    />
                                                :
                                                    index === 1
                                                ?
                                                    <TextField 
                                                        label={`N Individuals`} 
                                                        variant="outlined" 
                                                        size="small" 
                                                        value={numDyslexic} 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*$/.test(value)) {
                                                                if (value === '' || (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 8)) {
                                                                    setNumDyslexic(value);
                                                                }
                                                            }
                                                        }} 
                                                    />
                                                :
                                                    <Grid container>
                                                        <Grid item sx={{ mr: 2 }}>
                                                            <Box
                                                                style={{
                                                                width: 24,
                                                                height: 24,
                                                                backgroundColor: filledIndices.includes(index) ? '#B02418' : 'transparent',
                                                                border: '1px solid black',
                                                                cursor: 'pointer'
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography style={{color: '#B02418'}}>{row.description}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center" sx={{ mt: 4 }}>
                        {buttons.map((button, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                color="primary"
                                onClick={() => onClick(button.path)}
                                style={{ margin: '0 8px' }}
                                disabled={button.label === "Continue" && !hasResults}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
                <img src="Spark-Logo-lg.png" alt="Spark Interventions LLC" style={{ width: '100%', height: 'auto' }} />
            </Grid>
        </Grid>
        </Box>
    </div>
  );
};

export default SetupPage;
