import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EvaluationResultsPage = ({ title, buttons, individuals, y_pred, y_true }) => {
    const navigate = useNavigate();

    const onClick = (path) => {
        console.log(path);
        navigate(path);
    }

  return (
    <Box style={{ backgroundColor: '#F8F3EC' }} padding={4}>
        <Grid container mb={4}>
            <Grid item md={9}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src="Spark-Logo-sm.png" alt="Spark Interventions LLC" style={{ width: '25%', height: 'auto' }} />
            </Grid>
        </Grid>
      <Grid container>
        <Grid item md={12} padding={4}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item style={{ padding: '2px' }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h6" sx={{textDecoration: 'underline'}} display="inline" align="center">Prediction Results</Typography>
                        </Grid>
                        {individuals.map((individual, index) => (
                            <Grid item>
                                <Typography key={individual} variant="body1" style={{ color: y_true[index] === y_pred[index] ? '#51AF53' : '#951F14' }} align="center">{index+1}. {individual} is {y_true[index] === 0 ? 'not' : null} dyslexic</Typography>
                            </Grid>
                        ))}
                        <Grid item sx={{ mt: 2 }}>
                            <Grid container direction="column" alignItems="left">
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item sx={{ mb: 1 }}>
                                            <Box
                                                style={{
                                                width: 24,
                                                height: 24,
                                                backgroundColor: '#51AF53',
                                                border: '1px solid black',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Typography>Correct Prediction</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item sx={{ mb: 1 }}>
                                            <Box
                                                style={{
                                                width: 24,
                                                height: 24,
                                                backgroundColor: '#B02418',
                                                border: '1px solid black',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>Incorrect Prediction (Correct label shown)</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item alignItems="center" sx={{ mt: 4 }}>
                    {buttons.map((button, index) => (
                        <Button
                        key={index}
                        variant="contained"
                        color="primary"
                        onClick={() => onClick(button.path)}
                        style={{ margin: '0 8px' }}
                        >
                            {button.label}
                        </Button>
                    ))}
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EvaluationResultsPage;
